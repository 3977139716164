import React from 'react'
import { Link } from "gatsby"
import Twitter from "../images/twitter.svg"
import Instagram from "../images/instagram.svg"
import Facebook from "../images/facebook.svg"

import './footer.css'
export default function Footer() {
    return (
        <div className="footer__container">
            <div className="footer__block">
                <div className="footer__textwrapper">
                  <div className="footer__text">
                    Get in touch.
                  </div>                    
                </div> 
                <div className="footer__subtextwrapper">
                  <div className="footer__subtext">
                    Contact us to find how we can help you. If it's easier you can email us at <a href="mailto:support@tete.ng" style={{textDecoration: "none", color: "#B2B8E5"}}>support@tete.ng</a> or Whatsapp <a href="https://api.whatsapp.com/send?phone=2349028889378"  style={{textDecoration: "none", color: "#B2B8E5"}}>(+234) 09028889378</a>.
                  </div>               
                </div>    
                <div className="footer__contact">
                  <div className="copyright">
                      <div>
                          ©2020 Tete Nigeria
                      </div>
                  </div>
                  <div className="otherpages">
                      <div>
                        <Link className="footer__links" to="/privacy">
                          Privacy Policy
                        </Link><span className="dot">.</span> 
                        <Link className="footer__links" to="/terms">
                          Terms And Conditions
                        </Link>
                      </div>
                  </div>
                  <div className="socials">
                    <a href="https://instagram.com/tete__ng?igshid=14n9gl3d0qebf">
                      <img className="footer__image" src={Instagram} alt="instagram"/>
                    </a>
                    <a href="https://twitter.com/tete__ng?s=12">
                      <img className="footer__image" src={Twitter} alt="twitter"/>
                    </a>
                    <img className="footer__image" src={Facebook} alt="facebook"/>
                  </div>

                </div>        
            </div>
        </div>
    )
}
